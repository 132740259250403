import React from "react";

const ErrorPage = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Oops! Something Went Wrong</h1>
      <p style={styles.message}>MSISDN Not Found, Kindly switch to Safaricom Mobile Data!. Please try again later.</p>
      <a href="/" style={styles.link}>Go back to the homepage</a>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "#f8d7da",
    color: "#721c24",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "'Arial', sans-serif",
  },
  title: {
    fontSize: "32px",
    marginBottom: "20px",
  },
  message: {
    fontSize: "18px",
    marginBottom: "20px",
  },
  link: {
    color: "#007bff",
    textDecoration: "none",
    fontSize: "16px",
  },
};

export default ErrorPage;
