import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useSearchParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import '../pages/Home.css';
import QuizBoxLogo from "../assets/quizboxlogo.png";

export const Home = () => {
    const [loading, setLoading] = useState(true);
    const [msisdn, setMsisdn] = useState(null);
    const [host, setHost] = useState(null);
    const [agent, setAgent] = useState(null);
    const [token, setToken] = useState(null);
    const [extRef, setExtRef] = useState(null);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const callApi = async (url, options = {}) => {
        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error(`API Error: ${response.status} ${response.statusText}`);
            }
            return await response.json();
        } catch (err) {
            navigate("*");
            return null;
        }
    };

    const fetchToken = async () => {
        const url = "https://he-api.subscrption.gamepoa.com/appToken/getHashedToken";
        return await callApi(url);
    };

    const fetchMsisdn = async (token) => {
        const url = "https://identity.safaricom.com/partner/api/v2/fetchMaskedMsisdn";
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-App": "he-partner",
            "X-Source-System": "he-partner",
            "X-messageid": "1234",
        };
        return await callApi(url, { method: "GET", headers });
    };

    const fetchUserAgent = async () => {
        const url = "https://notificationafricom.digitizeconsultant.com/africom/v1/header";
        return await callApi(url, { method: "POST" });
    };

    const performSubscription = async (msisdn, user_agent, host) => {
        const url = "https://api.mfilter.gamepoa.com/app/v1/subscribe";
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        };
        const body = {
            msisdn,
            offerCode: "1736928578466",
            redirectUrl: "https://apiquizdemo.gamewins.co.za/",
            clickId: extRef,
            sourceIp: host,
            userAgent: user_agent,
        };
        return await callApi(url, { method: "POST", headers, body: JSON.stringify(body) });
    };



    const sendPromotionData = async (msisdn_user, mfid) => {
        const promotionRequestBody = {
            msisdn : msisdn_user,
          ext_ref: extRef,
          serviceName: "QuizBox_1",
          reference: mfid, 
        };
        try{
      
          const response = await fetch("https://notificationafricom.digitizeconsultant.com/promotion/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(promotionRequestBody),
          });
        }catch(error){
          
        }
      };

    useEffect(() => {
        const executeFlow = async () => {
            try {
                const userAgent = navigator.userAgent;
                setAgent(userAgent);

                const ext_ref_param = searchParams.get("ext_ref");
                setExtRef(ext_ref_param || uuidv4());

                const tokenData = await fetchToken();
                if (!tokenData) return;

                const fetchedToken = tokenData.access_token;
                setToken(fetchedToken);

                const msisdnData = await fetchMsisdn(fetchedToken);
                if (!msisdnData) return;

                const fetchedMsisdn = msisdnData.ServiceResponse.ResponseBody.Response.Msisdn;
                if (!fetchedMsisdn) {
                    navigate("*");
                    return;
                }
                setMsisdn(fetchedMsisdn);

                const userAgentData = await fetchUserAgent();
                if (!userAgentData) return;

                setHost(userAgentData["host"]);

                const subscriptionData = await performSubscription(fetchedMsisdn, userAgent, userAgentData["host"]);
                if (!subscriptionData) return; 
                const mfId = subscriptionData.mf_id; 

                 // sending mf_id in backend for promotion data controll
                const userInfo = await sendPromotionData(msisdn, mfId);

                if (subscriptionData.cg_url) {
                    window.location.href = subscriptionData.cg_url;
                } else {
                    navigate("*");
                }
            } catch {
                navigate("*");
            } finally {
                setLoading(false);
            }
        };

        executeFlow();
    }, [navigate, searchParams]);

    return (
        <div className="container">
            <img src={QuizBoxLogo} alt="quiz-box" className="logoImage" />
            <h1 className="title">QuizBox</h1>
            {loading && <ClipLoader color="#00FF00" size={50} />}
            <p className="costText">Cost: 10 KSH / day</p>
            <a href={`/terms?svc=quizbox`} className="termsLink">
                Terms & Conditions
            </a>
        </div>
    );
};
