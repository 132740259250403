import React from "react";
import {Routes,Route} from 'react-router-dom'
import TermsAndConditions from "./pages/TermsAndCondition.js";
import ErrorPage from "./pages/ErrorPage.js";
import { Home } from "./pages/Home.js";
// import { HomeNew } from "./pages/HomeNew.js";

const App = () => {
return (
  
   <Routes>
    
    {/* <Route path='/' element={ <HomeNew/>}/> */}
    {/* this is now on live with one click */}
    <Route path='/' element={ <Home/>}/>
    <Route path='/terms' element={ <TermsAndConditions/>}/>
    <Route path='*' element={ <ErrorPage/>}/>
   </Routes>
  
);
}


export default App;
